


































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PhotoOverlay from './PhotoOverlay.vue';

export default Vue.extend({
  props: {
    photos: {
      type: Array,
      required: true
    },
    allowSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    PhotoOverlay,
  },
  data(): any {
    return {
      viewingPhoto: null,
      currentPhotoIndex: 0,
    } as any;
  },
  computed: {
    ...mapGetters('photos', [
      'hasSelectedPhotos',
      'isPhotoSelected',
      'selectedPhotoIds',
    ]),
  },
  methods: {
    ...mapActions('photos', [
      'togglePhotoSelected'
    ]),

    viewPhoto(photo, index) {
      this.currentPhotoIndex = index;
      this.viewingPhoto = photo;
    },
    closePhotoViewer() {
      this.viewingPhoto = null;
    },
    goToNextItem() {
      this.currentPhotoIndex = this.currentPhotoIndex < this.photos.length - 1 ? this.currentPhotoIndex + 1 : 0;
      this.viewingPhoto = this.photos[this.currentPhotoIndex];
    },
    goToPreviousItem() {
      this.currentPhotoIndex = this.currentPhotoIndex > 0 ? this.currentPhotoIndex - 1 : this.photos.length - 1;
      this.viewingPhoto = this.photos[this.currentPhotoIndex];
    },
  },
})
