














































// Rewritten from https://github.com/silencesys/silentbox/blob/master/src/components/overlay.vue
import Vue from 'vue';
export default Vue.extend({
  props: {
    photo: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      animationName: 'lightbox-swipe-left',
      touchHandling: {
        posX: 0,
        posY: 0,
      },
    };
  },
  created() {
    window.addEventListener('keyup', this.handleKeys, true);

    this.enableScrollLock()
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeys, true);
  },
  methods: {
    handleKeys(event) {
      // Escape: 27 - close
      if (event.keyCode === 27) {
        this.close();
      }

      // Right arrow: 39 - next
      if (event.keyCode === 39) {
        this.goToNextItem();
      }

      // Left arrow: 37 - previous
      if (event.keyCode === 37) {
        this.goToPreviousItem();
      }
    },

    // Registers the finger position so we can calculate swipe direction later
    touchStart(event) {
      const { clientX: x, clientY: y } = event.touches[0];
      this.touchHandling.posX = x;
      this.touchHandling.posY = y;
    },

    // Handles touch movement event (only left/right right now)
    touchMove(event) {
      const { clientX: x, clientY: y } = event.touches[0];
      const { posX, posY } = this.touchHandling;

      if (posX === 0 || posY === 0) {
        return;
      }

      const xDiff = posX - x;
      const yDiff = posY - y;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          // Left
          this.goToNextItem();
        } else {
          // Right
          this.goToPreviousItem();
        }
      } else {
        if (yDiff > 0) {
          // up
        } else {
          // down
          // Close?
        }
      }

      this.touchHandling.posX = 0;
      this.touchHandling.posY = 0;
    },

    zoom(event) {
      console.log('mouse wheel / zoom', event);
    },

    close() {
      this.removeScrollLock();
      this.$emit('close');
    },

    goToNextItem() {
      this.animationName = 'lightbox-swipe-left';
      this.$emit('requestNextItem');
    },

    goToPreviousItem() {
      this.animationName = 'lightbox-swipe-right';
      this.$emit('requestPreviousItem');
    },

    // Disables scroll locking to make touch movements less confusing
    enableScrollLock() {
      if (!document.body.classList.contains('lightbox-open')) {
        return document.body.classList.add('lightbox-open')
      }
    },

    // Removes scroll lock
    removeScrollLock() {
      if (document.body.classList.contains('lightbox-open')) {
        return document.body.classList.remove('lightbox-open');
      }
    },
  },
});
