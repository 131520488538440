























import Vue, { PropType } from 'vue';
export default Vue.extend({
  props: {
    photos: {
      type: Array,
      required: true
    },
    selectedId: {
      type: String,
      required: false,
      default: ''
    },
    selectedIds: {
      type: Array as PropType<String[]>,
      required: false,
      default: () => []
    },
  },
  data() {
    return {
      photoId: '',
      photoIds: [] as String[],
    };
  },
  created() {
    this.photoId = this.selectedId;

    // backwards compat for now to enable a single initial selection
    if (this.selectedId) {
      this.photoIds = [...this.selectedIds, this.selectedId as String];
    } else {
      this.photoIds = this.selectedIds;
    }
  },
  methods: {
    selectPhoto(id: string) {
      this.photoId = id;

      if (this.photoIds.includes(id)) {
        // Photo already selected, remove it
        this.photoIds = this.photoIds.filter(x => x !== id);
      } else {
        // Photo not already selected, add it
        this.photoIds = [...this.photoIds, id];
      }
    },
    closeSelector() {
      this.$emit('photoSelected', this.photoId);
      this.$emit('photosSelected', this.photoIds);
    }
  },
})
