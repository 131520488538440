





































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { MetaInfo } from 'vue-meta';
import authHelpers from '../../authHelpers';
import LoadingIndicator from '../../components/LoadingIndicator.vue';
import PhotoGrid from '../../components/photos/PhotoGrid.vue';
import PhotoSelector from'../../components/photos/PhotoSelector.vue';

export default Vue.extend({
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isAuthenticated: false,
  }),
  components: {
    LoadingIndicator,
    PhotoGrid,
    PhotoSelector,
  },
  computed: {
    ...mapGetters('collections', [
      'currentCollection',
      'collectionPhotos',
    ]),
    ...mapGetters('photos', [
      'allPhotos'
    ]),
    ...mapFields('collections', [
      'showPhotoSelector',
      'currentCollection.photoIds'
    ]),
  },
  watch: {
    slug: 'refreshCollection'
  },
  async created() {
    this.refreshCollection();

    this.isAuthenticated = await authHelpers.isAuthenticated();
  },
  methods: {
    ...mapActions('collections', [
      'loadCollection',
      'loadCollectionPhotos',
      'openPhotoSelector',
      'updateCollectionPhotos',
    ]),

    async refreshCollection(){
      this.loadCollection(this.slug);
      this.loadCollectionPhotos(this.slug);
    },

    async selectedPhotosChanged(photoIds: number[]) {
      await this.updateCollectionPhotos(photoIds);
    },
  },
  metaInfo(this: any): MetaInfo {
    return {
      title: this.currentCollection ? this.currentCollection.name : '',
    }
  },
});
